import { Input, Modal, ModalOverlay, ModalContent, ModalHeader, Flex, ModalCloseButton, ModalBody, Button, FormLabel, Box, Select } from "@chakra-ui/react";
import { useState, useEffect } from 'react';
import axios from 'axios';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export default function ModifyAdModal({ isOpen, onClose }) {
  const [id, setId] = useState('');  
  const [email, setEmail] = useState("");
  const [fullName, setFullName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [userType, setUserType] = useState("");
  const [officialCompanyName, setOfficialCompanyName] = useState("");
  const [taxNumber, setTaxNumber] = useState("");
  const [isMuellif, setIsMuellif] = useState(false);
  const [muellifStartDate, setMuellifStartDate] = useState(new Date());
  const [taxPlatePhotoPath, setTaxPlatePhotoPath] = useState("");
  const [signatureCircularPhotoPath, setSignatureCircularPhotoPath] = useState("");
  const [tradeRegistryGazettePhotoPath, setTradeRegistryGazettePhotoPath] = useState("");
  const [agencyAuthorizationDocumentPath, setAgencyAuthorizationDocumentPath] = useState("");
  const [isActive , setIsActive] = useState(false); 

  const [isLoading, setIsLoading] = useState(false);

  const customDatePickerStyle = {
    backgroundColor: "white",
    borderColor: "gray.300",
    borderWidth: "1px",
    borderRadius: "md",
    padding: "2",
    _hover: {
      borderColor: "gray.400",
    },
    _focus: {
      boxShadow: "outline",
      borderColor: "blue.500",
    },
  };

  // Add date validation function
  const parseValidDate = (dateString) => {
    const parsedDate = new Date(dateString);
    return !isNaN(parsedDate) ? parsedDate : new Date(); 
  };

  useEffect(() => {
    if (isOpen && id) {
      setIsLoading(true);
      const token = localStorage.getItem('token');
      const apiUrl = `http://127.0.0.1:8000/users/${id}`; 

      const config = {
        headers: { Authorization: `Bearer ${token}` }
      };
      
      axios.get(apiUrl, config)
        .then(response => {
          const userData = response.data;
          console.log('User data:');
          console.log(userData);
          setEmail(userData.email);
          setFullName(userData.full_name);
          setPhoneNumber(userData.phone_number);
          setUserType(userData.user_type);
          setOfficialCompanyName(userData.official_company_name);
          setTaxNumber(userData.tax_number);
          setIsMuellif(userData.is_author);
          setIsActive(userData.is_active);
          setMuellifStartDate(parseValidDate(userData.muellif_start_date));
          setTaxPlatePhotoPath(userData.tax_plate_photo_path);
          setSignatureCircularPhotoPath(userData.signature_circular_photo_path);
          setTradeRegistryGazettePhotoPath(userData.trade_registry_gazette_photo_path);
          setAgencyAuthorizationDocumentPath(userData.agency_authorization_document_path);
        })
        .catch(error => console.error(error))
        .finally(() => setIsLoading(false));
    }
  }, [isOpen, id]);

  const handleSubmit = async () => {
    const formattedStartDate = muellifStartDate instanceof Date ? muellifStartDate.toISOString().split('T')[0] : '';
  
    const userData = {
      email,
      full_name: fullName,
      phone_number: phoneNumber,
      user_type: userType,
      official_company_name: officialCompanyName,
      tax_number: taxNumber,
      is_author: isMuellif, 
      muellif_start_date: formattedStartDate,
      tax_plate_photo_path: taxPlatePhotoPath,
      signature_circular_photo_path: signatureCircularPhotoPath,
      trade_registry_gazette_photo_path: tradeRegistryGazettePhotoPath,
      agency_authorization_document_path: agencyAuthorizationDocumentPath,
      is_active: isActive 
    };
    console.log('User data to update:');
    console.log(userData);
    try {
      const token = localStorage.getItem('token');
      const apiUrl = `http://127.0.0.1:8000/users/${id}`;
      
      const config = {
        headers: { Authorization: `Bearer ${token}` }
      };
      const response = await axios.put(apiUrl, userData, config);
      console.log('Response data:');
      console.log(response.data);
      if (response.status === 200) {
        alert(`${email} kullanıcısı başarıyla güncellendi.`);
      }
    } catch (error) {
      console.error(error);
    }
  };
  
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Kullanıcı Bilgileri Güncelle</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Flex direction="column">
            <FormLabel>Id</FormLabel>
            <Input placeholder="Id" value={id} onChange={(e) => setId(e.target.value)} />
            <FormLabel>Email</FormLabel>
            <Input placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} />
  
            <FormLabel>Adı Soyadı</FormLabel>
            <Input placeholder="Tam Adı" value={fullName} onChange={(e) => setFullName(e.target.value)} />
  
            <FormLabel>Telefon Numarası</FormLabel>
            <Input placeholder="Telefon Numarası" value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} />
  
            <FormLabel>Kullanıcı Tipi</FormLabel>
            <Input placeholder="Kullanıcı Tipi" value={userType} onChange={(e) => setUserType(e.target.value)} />
  
            <FormLabel>Resmi Şirket İsmi</FormLabel>
            <Input placeholder="Resmi Şirket İsmi" value={officialCompanyName} onChange={(e) => setOfficialCompanyName(e.target.value)} />
  
            <FormLabel>Vergi Numarası</FormLabel>
            <Input placeholder="Vergi Numarası" value={taxNumber} onChange={(e) => setTaxNumber(e.target.value)} />

            <FormLabel>Aktif mi?</FormLabel>
            <Select value={isActive ? 'true' : 'false'} onChange={(e) => setIsActive(e.target.value === 'true')}>
                <option value="true">Evet</option>
                <option value="false">Hayır</option>
            </Select>

            <FormLabel>Müellif mi?</FormLabel>
            <Select value={isMuellif ? 'true' : 'false'} onChange={(e) => setIsMuellif(e.target.value === 'true')}>
                <option value="true">Evet</option>
                <option value="false">Hayır</option>
            </Select>
            
            <Box mb="4">
              <FormLabel>Müellif Başlangıç Tarihi</FormLabel>
              <DatePicker
                selected={muellifStartDate}
                onChange={(date) => setMuellifStartDate(date)}
                locale='tr'
                customInput={<Input style={customDatePickerStyle} />}
              />
            </Box>
  
            <FormLabel>Vergi Levhası Fotoğrafı</FormLabel>
            <Input placeholder="Vergi Levhası Fotoğrafı Yolu" value={taxPlatePhotoPath} onChange={(e) => setTaxPlatePhotoPath(e.target.value)} />
  
            <FormLabel>İmza Sirküleri Fotoğrafı</FormLabel>
            <Input placeholder="İmza Sirküleri Fotoğrafı Yolu" value={signatureCircularPhotoPath} onChange={(e) => setSignatureCircularPhotoPath(e.target.value)} />
  
            <FormLabel>Ticaret Sicil Gazetesi Fotoğrafı</FormLabel>
            <Input placeholder="Ticaret Sicil Gazetesi Fotoğrafı Yolu" value={tradeRegistryGazettePhotoPath} onChange={(e) => setTradeRegistryGazettePhotoPath(e.target.value)} />
  
            <FormLabel>Yetkilendirme Belgeleri</FormLabel>
            <Input placeholder="Yetkilendirme Belgeleri Yolu" value={agencyAuthorizationDocumentPath} onChange={(e) => setAgencyAuthorizationDocumentPath(e.target.value)} />
  
            <Button 
              colorScheme="green" 
              onClick={handleSubmit} 
              style={{ 
                marginTop: '20px', 
                marginBottom: '20px', 
                display: 'block', 
                marginLeft: 'auto', 
                marginRight: 'auto' 
              }}
            >
              Kaydet
            </Button>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
