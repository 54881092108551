import { Input, Modal, ModalOverlay, ModalContent, ModalHeader, Flex, ModalCloseButton, ModalBody, Button, FormLabel, Box } from "@chakra-ui/react";
import { useState } from 'react';
import axios from 'axios';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";


export default function CreateAdModal({ isOpen, onClose }) {
  const [ajans_ismi, setAjans_ismi] = useState("");
  const [yetki_belgesi_var, setYetki_belgesi_var] = useState("");
  const [yetki_belgesi_son_tarih, setYetki_belgesi_son_tarih] = useState(new Date());
  const [yetkili_isim_soyisim, setYetkili_isim_soyisim] = useState("");
  const [yetkili_tel_no, setYetkili_tel_no] = useState("");

  const handleSubmit = async () => {
    const adData = { ajans_ismi, yetki_belgesi_var, yetki_belgesi_son_tarih: yetki_belgesi_son_tarih.toISOString().split('T')[0], yetkili_isim_soyisim, yetkili_tel_no };
    
    const stringFields = { ajans_ismi, yetki_belgesi_var, yetkili_isim_soyisim, yetkili_tel_no };
    for (const [key, value] of Object.entries(stringFields)) {
      if (typeof value !== 'string') {
        alert(`Girdiğiniz alan ${key} yazı olmalıdır. Lütfen kontrol ediniz.`);
        return;
      }
    }

    try {
      const token = localStorage.getItem('token');
      const apiUrl = 'http://127.0.0.1:8000/ajanslar'
      const config = {
        headers: { Authorization: `Bearer ${token}` }
      };
      const response = await axios.post(apiUrl, adData, config);
      console.log(response.data);
      if (response.status === 200) {
        alert(` ${response.data.id} numaralı reklam ajansı başarıyla eklendi.`);
        // Reset the state of each input field
        setAjans_ismi("");
        setYetki_belgesi_var("");
        setYetki_belgesi_son_tarih("");
        setYetkili_isim_soyisim("");
        setYetkili_tel_no("");
      }
    } catch (error) {
      console.error(error);
    }
  };
  const customDatePickerStyle = {
    backgroundColor: "white",
    borderColor: "gray.300",
    borderWidth: "1px",
    borderRadius: "md",
    padding: "2",
    _hover: {
      borderColor: "gray.400",
    },
    _focus: {
      boxShadow: "outline",
      borderColor: "blue.500",
    },
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Yeni Ajans Ekle</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
        <Flex direction="column">
          <FormLabel>Ajans İsmi</FormLabel>
          <Input placeholder="Ajans İsmi" value={ajans_ismi} onChange={(e) => setAjans_ismi(e.target.value)} />

          <FormLabel>Yetki Belgesi Var</FormLabel>
          <Input placeholder="Yetki Belgesi Var" value={yetki_belgesi_var} onChange={(e) => setYetki_belgesi_var(e.target.value)} />

          <Box mb="4">
              <FormLabel>Yetki Belgesi Son Tarih</FormLabel>
              <DatePicker 
                selected={yetki_belgesi_son_tarih} 
                onChange={(date) => setYetki_belgesi_son_tarih(date)} 
                locale='tr'
                customInput={<Input style={customDatePickerStyle} />}
              />
            </Box>

          <FormLabel>Yetkili İsim Soyisim</FormLabel>
          <Input placeholder="Yetkili İsim Soyisim" value={yetkili_isim_soyisim} onChange={(e) => setYetkili_isim_soyisim(e.target.value)} />

          <FormLabel>Yetkili Tel No</FormLabel>
          <Input placeholder="Yetkili Tel No" value={yetkili_tel_no} onChange={(e) => setYetkili_tel_no(e.target.value)} />

          <Button 
            colorScheme="green" 
            onClick={handleSubmit} 
            style={{ 
              marginTop: '20px', 
              marginBottom: '20px', 
              display: 'block', 
              marginLeft: 'auto', 
              marginRight: 'auto' 
            }}
          >
            Kaydet
          </Button>
        </Flex>
      </ModalBody>
      </ModalContent>
    </Modal>
  );
}