import { Input, Modal, ModalOverlay, ModalContent, Select, ModalHeader, Flex, ModalCloseButton, ModalBody, Button, FormLabel,Box } from "@chakra-ui/react";
import { useState, useEffect } from 'react';
import axios from 'axios';
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import tr from 'date-fns/locale/tr'; // Importing Turkish locale

export default function ModifyAdModal({ isOpen, onClose }) {
  const [id, setId] = useState("");
  const [network_no, setNetwork_no] = useState("");
  const [kiralama_tipi, setKiralama_tipi] = useState("Haftalık");
  const [kiralayan_ajans, setKiralayan_ajans] = useState("");
  const [ajans_id, setAjans_id] = useState("");
  const [kira_baslangic, setKira_baslangic] = useState(new Date());
  const [kira_bitis, setKira_bitis] = useState(new Date());
  const [reklam_icerigi, setReklam_icerigi] = useState("");
  
  const [isLoading, setIsLoading] = useState(false);
  const customDatePickerStyle = {
    backgroundColor: "white",
    borderColor: "gray.300",
    borderWidth: "1px",
    borderRadius: "md",
    padding: "2",
    _hover: {
      borderColor: "gray.400",
    },
    _focus: {
      boxShadow: "outline",
      borderColor: "blue.500",
    },
  };
  useEffect(() => {
    if (isOpen && network_no) {
      setIsLoading(true);
      const token = localStorage.getItem('token');
      const apiUrl =`http://127.0.0.1:8000/rentingsno/${network_no}`
      const config = {
        headers: { Authorization: `Bearer ${token}` }
      };
      axios.get(apiUrl, config)
        .then(response => {
          const adData = response.data;
          setId(adData.id);
          setNetwork_no(adData.network_no);
          setKiralama_tipi(adData.kiralama_tipi);
          setKiralayan_ajans(adData.kiralayan_ajans);
          setAjans_id(adData.ajans_id);
          setKira_baslangic(new Date(adData.kira_baslangic));
          setKira_bitis(new Date(adData.kira_bitis));
          setReklam_icerigi(adData.reklam_icerigi);

        })
        .catch(error => console.error(error))
        .finally(() => setIsLoading(false));
    }
  }, [isOpen, network_no]);
  const handleKiralamaTipiChange = (e) => {
    const selectedValue = e.target.value;
    console.log('Selected Kiralama Tipi:', selectedValue); // Debugging log
    setKiralama_tipi(selectedValue);
  };
  const handleSubmit = async () => {
    const adData = { network_no,
      kiralayan_ajans,
      ajans_id,
      kiralama_tipi,
      kira_baslangic: kira_baslangic.toISOString().split('T')[0],
      kira_bitis: kira_bitis.toISOString().split('T')[0],
      reklam_icerigi};
      const stringFields = { network_no, kiralayan_ajans, reklam_icerigi};
      for (const [key, value] of Object.entries(stringFields)) {
        if (typeof value !== 'string') {
          alert(`Girdiğiniz alan ${key} yazı olmalıdır. Lütfen kontrol ediniz.`);
          return;
        }
      }
  
      const floatFields = { ajans_id };
      for (const [key, value] of Object.entries(floatFields)) {
        if (isNaN(value)) {
          alert(`Girdiğiniz alan ${key} sayı örn 123 olmalıdır. Lütfen kontrol ediniz.`);
          return;
        }
      }
    try {
      const token = localStorage.getItem('token');
      const apiUrl = `http://127.0.0.1:8000/renting/${id}`
      const config = {
        headers: { Authorization: `Bearer ${token}` }
      };
      const response = await axios.put(apiUrl, adData, config);
      console.log(response.data);
      if (response.status === 200) {
        alert(` ${response.data.network_no} numaralı kiralama başarıyla güncellendi.`);
        // Reset the state of each input field
        setNetwork_no("");
        setKiralama_tipi("");
        setKiralayan_ajans("");
        setAjans_id("");
        setKira_baslangic(new Date());
        setKira_bitis(new Date());
        setReklam_icerigi("");
        

      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Kiralama Bilgisini Güncelle</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Flex direction="column">
            {/* Existing Input fields... */}
            
            {/* New Input fields */}
            <FormLabel>Reklam Network Alanı No </FormLabel>
            <Input placeholder="Network No" value={network_no} onChange={(e) => setNetwork_no(e.target.value)} />

            <FormLabel>Kiralama Tipi </FormLabel>
            <Select value={kiralama_tipi} onChange={handleKiralamaTipiChange}>
              <option value="Haftalık">Haftalık</option>
              <option value="Aylık">Aylık</option>
              <option value="3 Aylık">3 Aylık</option>
              <option value="6 aylık">6 Aylık</option>
            </Select>
            <FormLabel>Kiralayan Ajans</FormLabel>
            <Input placeholder="Kiralayan Ajans" value={kiralayan_ajans} onChange={(e) => setKiralayan_ajans(e.target.value)} />

            <FormLabel>Ajans ID</FormLabel>
            <Input placeholder="Ajans ID" value={ajans_id} onChange={(e) => setAjans_id(e.target.value)} />

            <Box mb="4">
              <FormLabel>Kira Başlangıç</FormLabel>
              <DatePicker 
                selected={kira_baslangic} 
                onChange={date => setKira_baslangic(date)} 
                locale='tr'
                customInput={<Input style={customDatePickerStyle} />}
              />
            </Box>

            {/* Custom styled Date picker for Kira Bitiş */}
            <Box mb="4">
              <FormLabel>Kira Bitiş</FormLabel>
              <DatePicker 
                selected={kira_bitis} 
                onChange={date => setKira_bitis(date)} 
                locale='tr'
                customInput={<Input style={customDatePickerStyle} />}
              />
            </Box>

            <FormLabel>Reklam İçeriği</FormLabel>
            <Input placeholder="Reklam İçeriği" value={reklam_icerigi} onChange={(e) => setReklam_icerigi(e.target.value)} />

            {/* Existing Save Button */}
            <Button 
              colorScheme="green" 
              onClick={handleSubmit} 
              style={{ 
                marginTop: '20px', 
                marginBottom: '20px', 
                display: 'block', 
                marginLeft: 'auto', 
                marginRight: 'auto' 
              }}
            >
              Kaydet
            </Button>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}