import { useState } from "react";
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, Button, Input, FormLabel, useToast } from "@chakra-ui/react";
import axios from "axios";

export default function ActivateUserModal({ isOpen, onClose }) {
  const [userId, setUserId] = useState("");
  const toast = useToast();

  const handleActivateUser = async () => {
    try {
      const response = await axios.put(`http://127.0.0.1:8000/users/activate/${userId}`);
      if (response.status === 200) {
        toast({
          title: "Success",
          description: "User activated successfully",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        onClose();
      }
    } catch (error) {
      toast({
        title: "Error",
        description: "Failed to activate user",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Activate User</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormLabel>User ID</FormLabel>
          <Input
            placeholder="Enter User ID"
            value={userId}
            onChange={(e) => setUserId(e.target.value)}
          />
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={handleActivateUser}>
            Activate
          </Button>
          <Button variant="ghost" onClick={onClose}>
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
