import {
  Flex,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr, Input,
  useColorModeValue,
  Button, 
  useDisclosure,
  Spinner,
  Center } from "@chakra-ui/react";

import Card from "components/card/Card";
import React, { useMemo, useState } from "react";
import { useGlobalFilter, usePagination, useSortBy, useTable } from "react-table";
import CreateAdModal from './CreateAdModal';
import ModifyAdModal from './ModifyAdModal';
import DeleteAdModal from './DeleteAdModal';


export default function DevelopmentTable({ columnsData, tableData, isLoading, currentPage, totalPages, onPageChange, onReload }) {
  
  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => tableData, [tableData]);
  const { isOpen: isAddOpen, onOpen: onAddOpen, onClose: onAddClose } = useDisclosure();
  const { isOpen: isModifyOpen, onOpen: onModifyOpen, onClose: onModifyClose } = useDisclosure();
  const { isOpen: isDeleteOpen, onOpen: onDeleteOpen, onClose: onDeleteClose } = useDisclosure();

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 5 }, // initialState is a property here
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    setGlobalFilter,
    state: { pageIndex, globalFilter },
    pageOptions,
  } = tableInstance;
  const canNextPage = currentPage < totalPages;
  const canPreviousPage = currentPage > 1;

  // Function to handle page change
  const nextPage = () => {
    if (canNextPage) onPageChange(currentPage + 1);
  };

  const previousPage = () => {
    if (canPreviousPage) onPageChange(currentPage - 1);
  };
  const [searchValue, setSearchValue] = useState(globalFilter);
  const handleSearch = (e) => {
    const value = e.target.value || undefined;
    setGlobalFilter(value);
    setSearchValue(value);
  };

  const textColor = useColorModeValue("secondaryGray.900", "white");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");
  if (isLoading) {
    return (
      <Center h="100vh">
        <Spinner size="xl" color="blue.500" />
      </Center>
    );
  }
  
  return (
  
    <Card
      direction='column'
      w='100%'
      px='0px'
      overflowX={{ sm: "scroll", lg: "hidden" }}>
      <Flex px='25px' justify='space-between' mb='20px' align='center'>
        <Text
          color={textColor}
          fontSize='22px'
          fontWeight='700'
          lineHeight='100%'>
          Reklam Alanları
        </Text>
        <Input
          value={searchValue}
          onChange={handleSearch}
          placeholder="Bu sayfada Arama"
          w={{ sm: "100%", lg: "400px" }}
        />
       
        <flex alignItems="center" pr={50} pt={50}> 
        <Button onClick={onReload} colorScheme="blue" mr={5}>Yenile</Button>
        <Button onClick={onAddOpen} colorScheme="linkedin" mr={5} >Yeni Reklam Alanı Ekle</Button>
        <Button onClick={onModifyOpen} colorScheme="green" mr={5}>Reklam Alanını Düzenle </Button>
        <Button onClick={onDeleteOpen} colorScheme="red" mr={5}>Reklam Alanını Sil </Button>
        </flex>
        <CreateAdModal isOpen={isAddOpen} onClose={onAddClose} />
        <ModifyAdModal isOpen={isModifyOpen} onClose={onModifyClose} />
        <DeleteAdModal isOpen={isDeleteOpen} onClose={onDeleteClose} />
        
      </Flex>
      <div style={{ overflowX: 'auto' }}>
      <Table {...getTableProps()} variant='simple' color='gray.500' mb='24px'>
        <Thead>
          {headerGroups.map((headerGroup, index) => (
            <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
              {headerGroup.headers.map((column, index) => (
                <Th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  pe='10px'
                  key={index}
                  borderColor={borderColor}>
                  <Flex
                    justify='space-between'
                    align='center'
                    fontSize={{ sm: "10px", lg: "12px" }}
                    color='gray.400'>
                    {column.render("Header")}
                  </Flex>
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody {...getTableBodyProps()}>
          {page.map((row, index) => {
            prepareRow(row);
            return (
              <Tr {...row.getRowProps()} key={index}>
                {row.cells.map((cell, index) => {
                  return (
                    <Td
                      {...cell.getCellProps()}
                      key={index}
                      fontSize={{ sm: "14px" }}
                      minW={{ sm: "150px", md: "200px", lg: "auto" }}
                      borderColor='transparent'>
                      <Text color={textColor} fontSize='sm' fontWeight='700'>
                        {cell.render('Cell')}
                      </Text>
                    </Td>
                  );
                })}
              </Tr>
            );
          })}
        </Tbody>
      </Table>
      </div>
      <Flex justifyContent="space-between" alignItems="center" pr={50} pt={50}>
      <Flex justifyContent="center" flex="1">
         {currentPage}. Sayfa / {totalPages} 
      </Flex>
      <Flex>
        <Button onClick={previousPage} disabled={!canPreviousPage} colorScheme="teal" variant="outline" mr={3}>
          Bir önceki
        </Button>
        <Button onClick={nextPage} disabled={!canNextPage} colorScheme="teal" variant="outline">
          İleri 
        </Button>
      </Flex>
    </Flex>
    </Card>
    
  
  );
}