import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, Button, FormControl, FormLabel, Input, useToast, Text } from "@chakra-ui/react";
import axios from 'axios';
import { useState, useRef } from 'react';

export default function UploadFileModal({ isOpen, onClose }) {
  const [file, setFile] = useState(null);
  const toast = useToast();
  const inputRef = useRef(null);
  const [uploadResult, setUploadResult] = useState(null); // Add this line


  const uploadFile = async () => {
    if (!file) {
      alert("Please select a file to upload")
      return;
    }

    const formData = new FormData();
    formData.append("file", file);

    try {
      const response = await axios.post("http://127.0.0.1:8000/uploadfile/", formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      setUploadResult(response.data); // Add this line

      toast({
        title: 'Uploaded Successfully',
        description: `File uploaded to: ${response.data.filename}`,
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
      setFile(null);
      inputRef.current.value = ""; // Reset the input after successful upload
      onClose();
    } catch (error) {
      console.error(error);
      toast({
        title: 'Error uploading file',
        description: error.response?.data || "An unexpected error occurred",
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Belge Yükle</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl>
            <FormLabel>Yüklemek istediğiniz belgeleri seçiniz (jpg, jpeg, png, pdf)</FormLabel>
            <Input type="file" onChange={(e) => setFile(e.target.files[0])} ref={inputRef} />
          </FormControl>
          <Button colorScheme="blue" onClick={uploadFile} p={4} mt={4}>Yükle</Button>
          {uploadResult && <Text mt={4}>Dosyanız başarıyla yüklendi. Dosyanızın yüklendiği adresi kopyalayın ve kullanıcı düzenleden bilgiyi düzenleyiniz: <br /><span style={{color: 'black'}}>{uploadResult.filename}</span></Text>}         
           </ModalBody>
      </ModalContent>
    </Modal>
  );
}
