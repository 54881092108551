import { Box, SimpleGrid, Link, Icon, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, Image, useDisclosure } from "@chakra-ui/react";
import { FiFileText } from "react-icons/fi";

export const columnsDataAdvertisingRenting = [
  {
    Header: "ID",
    accessor: "id"
  },
  {
    Header: "Reklam Numarası",
    accessor: "network_no"
  },
  {
    Header: "Kiralama Tipi",
    accessor: "kiralama_tipi"
  },
  {
    Header: "Kiralayan Ajans",
    accessor: "kiralayan_ajans"
  },
  {
    Header: "Kullanıcı ID",
    accessor: "user_id"
  },
  {
    Header: "Kira Başlangıç",
    accessor: "kira_baslangic"
  },
  {
    Header: "Kira Bitiş",
    accessor: "kira_bitis"
  },
  {
    Header: "Reklam İçeriği (Text)",
    accessor: "reklam_icerigi_text"
  },
  {
    Header: "Reklam İçeriği Fotoğrafı",
    accessor: "reklam_icerigi_photo_path",
    Cell: ({ value }) => {
      const { isOpen, onOpen, onClose } = useDisclosure();

      return value ? (
        <>
          <Link onClick={onOpen}>
            <Icon as={FiFileText} boxSize="24px" cursor="pointer" />
          </Link>

          <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>Fotoğraf</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
              <Image src={`http://127.0.0.1:8000/${value}`} alt="Reklam İçeriği Fotoğrafı" />          
              </ModalBody>
            </ModalContent>
          </Modal>
        </>
      ) : (
        <Box>Fotoğraf Bulunamadı</Box>
      );
    }
  },
  {
    Header: "Durumu",
    accessor: "status"
  }
];
