import { Box, SimpleGrid, Link, Icon, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, Image, useDisclosure } from "@chakra-ui/react";
import { FiFileText } from "react-icons/fi";

export const columnsDataReklamlar = [
  {
    Header: "ID",
    accessor: "id"
  },
  {
    Header: "NT",
    accessor: "nt"
  },
  {
    Header: "Tür",
    accessor: "tur"
  },
  {
    Header: "İlçe",
    accessor: "ilce"
  },
  {
    Header: "Numarası",
    accessor: "no"
  },
  {
    Header: "Adres",
    accessor: "adres"
  },
  {
    Header: "Yön",
    accessor: "yon"
  },
  {
    Header: "Tarif",
    accessor: "tarif"
  },
  {
    Header: "Enlem",
    accessor: "enlem"
  },
  {
    Header: "Boylam",
    accessor: "boylam"
  },
  {
    Header: "Google Maps Link",
    accessor: "googlemaps_link",
    Cell: ({ value }) => value ? <a href={value} target="_blank" rel="noopener noreferrer">Haritada Görüntüle</a> : 'Harita Yok'
  },
  {
    Header: "Reklam Alanı Eni",
    accessor: "reklam_alani_eni"
  },
  {
    Header: "Reklam Alanı Boyu",
    accessor: "reklam_alani_boyu"
  },
  {
    Header: "Reklam Alanı m²",
    accessor: "reklam_alani_m2"
  },
  {
    Header: "Kiralandı mı?",
    accessor: "rented",
    Cell: ({ value }) => value ? 'Evet' : 'Hayır'
  },
  {
    Header: "Aktif mi?",
    accessor: "active",
    Cell: ({ value }) => value ? 'Evet' : 'Hayır'
  },
  {
    Header: "Haftalık Bedel",
    accessor: "haftalik_bedel"
  },
  {
    Header: "Aylık Bedel",
    accessor: "aylik_bedel"
  },
  {
    Header: "3 Aylık Bedel",
    accessor: "uc_aylik_bedel"
  },
  {
    Header: "6 Aylık Bedel",
    accessor: "alti_aylik_bedel"
  },
  {
    Header: "Reklam Alanı Fotoğrafı",
    accessor: "reklam_alani_photo_path",
    Cell: ({ value }) => {
      const { isOpen, onOpen, onClose } = useDisclosure();

      return value ? (
        <>
          <Link onClick={onOpen}>
            <Icon as={FiFileText} boxSize="24px" cursor="pointer" />
          </Link>

          <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>Fotoğraf</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <Image src={value} alt="Reklam Alanı Fotoğrafı" />
              </ModalBody>
            </ModalContent>
          </Modal>
        </>
      ) : (
        <Box>Fotoğraf Bulunamadı</Box>
      );
    }
  }
];

