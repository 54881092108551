import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, Button, FormControl, FormLabel, Input } from "@chakra-ui/react";
import axios from 'axios';
import { useState } from 'react';

export default function DeleteAdModal({ isOpen, onClose }) {
  const [adId, setAdId] = useState('');

  const deleteAd = async () => {
    try {
      const token = localStorage.getItem('token');
      const apiUrl = `http://127.0.0.1:8000/users/${adId}`
      const config = {
        headers: { Authorization: `Bearer ${token}` }
      };
      const response = await axios.delete(apiUrl, config);
      setAdId('');
      console.log(response.data);
      onClose();
      alert('Reklam ajansı başarıyla silindi');
    } catch (error) {
      console.error(error);
      alert('Reklam ajansı silinirken bir hata oluştu. Lütfen tekrar deneyiniz.');
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Reklam Ajansı Sil</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl>
            <FormLabel>Reklam Ajansının ID giriniz</FormLabel>
            <Input value={adId} onChange={(e) => setAdId(e.target.value)} />
          </FormControl>
          <Button colorScheme="red" onClick={deleteAd} p={4} mt={4}>Sil</Button>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}