import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, Button, FormControl, FormLabel, Input, Textarea, Select, Link, Spinner } from "@chakra-ui/react";
import { useState, useEffect } from 'react';
import axios from 'axios';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import tr from 'date-fns/locale/tr';

registerLocale('tr', tr);

export default function CreateRentingModal({ isOpen, onClose }) {
  const [networkNo, setNetworkNo] = useState('');
  const [isNetworkRented, setIsNetworkRented] = useState(false);
  const [isCheckingNetwork, setIsCheckingNetwork] = useState(false);
  const [kiralayanAjans, setKiralayanAjans] = useState('');
  const [userId, setUserId] = useState('');
  const [kiralamaTipi, setKiralamaTipi] = useState('');
  const [kiraBaslangic, setKiraBaslangic] = useState(new Date());
  const [reklamIcerigiText, setReklamIcerigiText] = useState('');
  const [file, setFile] = useState(null);
  const [sozlesmeImzaliFotopikisi, setSozlesmeImzaliFotopikisi] = useState(null);

  useEffect(() => {
    setKiraBaslangic(getNextMonday(new Date()));
  }, []);

  useEffect(() => {
    if (networkNo) {
      setIsCheckingNetwork(true);
      const timer = setTimeout(() => {
        checkNetworkNo(networkNo);
      }, 500);
      return () => clearTimeout(timer);
    }
  }, [networkNo]);

  useEffect(() => {
    // Fetch token from localStorage
    const token = localStorage.getItem('token');
    if (token) {
      // Decode token to get user info

      // Fetch official company name from the API
      const fetchCompanyName = async () => {
        try {
          const response = await axios.get('http://127.0.0.1:8000/users-company-name', {
            headers: { Authorization: `Bearer ${token}` }
          });
          setKiralayanAjans(response.data.official_company_name);
          setUserId(parseInt(response.data.id, 10));
        } catch (error) {
          console.error('Şirket adı getirilirken hata oluştu:', error);
        }
      };
      fetchCompanyName();
    }
  }, []);

  const getNextMonday = (date) => {
    const resultDate = new Date(date);
    resultDate.setDate(resultDate.getDate() + ((1 + 7 - resultDate.getDay()) % 7));
    return resultDate;
  };

  const checkNetworkNo = async (networkNo) => {
    try {
      const response = await axios.get(`http://127.0.0.1:8000/check_rent_status/${networkNo}`);
      if (response.data.rented) {
        setIsNetworkRented(true);
      } else {
        setIsNetworkRented(false);
      }
    } catch (error) {
      console.error('Reklam numarası durumu kontrol edilirken hata oluştu:', error);
      setIsNetworkRented(true); // Assuming rented to block the form in case of error
    } finally {
      setIsCheckingNetwork(false);
    }
  };

  const handleNetworkNoChange = (e) => {
    const value = e.target.value;
    setNetworkNo(value);
  };

  const handleSubmit = async () => {
    const formData = new FormData();
    formData.append('network_no', networkNo);
    formData.append('kiralayan_ajans', kiralayanAjans);
    formData.append('user_id', userId);
    formData.append('kiralama_tipi', kiralamaTipi);
    formData.append('kira_baslangic', kiraBaslangic.toISOString().split('T')[0]);
    formData.append('reklam_icerigi_text', reklamIcerigiText);
    formData.append('file', file);
    formData.append('sozlesme_imzali_fotopikisi', sozlesmeImzaliFotopikisi);

    try {
      const token = localStorage.getItem('token');
      const config = {
        headers: { 
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`
        }
      };
      const response = await axios.post('http://127.0.0.1:8000/renting/', formData, config);
      console.log(response.data);
      onClose();
      alert('Kiralama talebiniz başarıyla oluşturuldu');
    } catch (error) {
      console.error(error);
      alert('Kiralama girişi oluşturulurken bir hata oluştu. Lütfen tekrar deneyiniz.');
    }
  };

  const filterDates = (date) => {
    return date.getDay() === 1; // Only allow Mondays
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Reklam Kiralama Talebi Oluştur</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl>
            <FormLabel>Reklam Network Numarası</FormLabel>
            <Input value={networkNo} onChange={handleNetworkNoChange} />
            {isCheckingNetwork && <Spinner size="sm" />}
          </FormControl>
          <FormControl isDisabled>
            <FormLabel>Kiralayan Ajans</FormLabel>
            <Input value={kiralayanAjans} readOnly />
          </FormControl>
          <FormControl isDisabled={isNetworkRented || isCheckingNetwork}>
            <FormLabel>Kiralama Tipi</FormLabel>
            <Select value={kiralamaTipi} onChange={(e) => setKiralamaTipi(e.target.value)}>
              <option value="">Seçiniz</option>
              <option value="haftalik">Haftalık</option>
              <option value="aylik">Aylık</option>
              <option value="3aylik">3 Aylık</option>
              <option value="6aylik">6 Aylık</option>
            </Select>
          </FormControl>
          <FormControl isDisabled={isNetworkRented || isCheckingNetwork}>
            <FormLabel>Kira Başlangıç</FormLabel>
            <DatePicker
              selected={kiraBaslangic}
              onChange={(date) => setKiraBaslangic(date)}
              dateFormat="dd-MM-yyyy"
              filterDate={filterDates}
              locale="tr"
              customInput={<Input />}
            />
          </FormControl>
          <FormControl isDisabled={isNetworkRented || isCheckingNetwork}>
            <FormLabel>Reklam İçeriği Açıklayınız</FormLabel>
            <Textarea value={reklamIcerigiText} onChange={(e) => setReklamIcerigiText(e.target.value)} />
          </FormControl>
          <FormControl isDisabled={isNetworkRented || isCheckingNetwork}>
            <FormLabel>Reklam Fotoğrafını yükleyin</FormLabel>
            <Input type="file" onChange={(e) => setFile(e.target.files[0])} />
          </FormControl>
          <FormControl isDisabled={isNetworkRented || isCheckingNetwork}>
            <FormLabel>Sözleşme İmzalı Fotokopisi</FormLabel>
            <Link href="https://example.com/example.pdf" isExternal>Reklam Sözleşmesini indirmek için tıklayın. İmzalayın ve sisteme yükleyiniz.</Link>
            <Input type="file" onChange={(e) => setSozlesmeImzaliFotopikisi(e.target.files[0])} />
          </FormControl>
          <Button colorScheme="blue" onClick={handleSubmit} p={4} mt={4} isDisabled={isNetworkRented || isCheckingNetwork}>Reklam Talebi Oluştur</Button>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
