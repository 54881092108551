import React, { useState, useEffect } from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import { Box, SimpleGrid, Spinner, Center, Select } from "@chakra-ui/react";
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import { Icon } from 'leaflet';
import { ChevronDownIcon } from '@chakra-ui/icons';



// Leaflet icon fix
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

export default function MapPage() {
  const [ads, setAds] = useState([]);
  const [mapLoaded, setMapLoaded] = useState(false);
  const [selectedTur, setSelectedTur] = useState('ALL');

  function getIconColor(tur) {
    let color;
    switch (tur) {
      case 'ALINLIK':
        color = 'alinlik';
        break;
      case 'ADML':
        color = 'megalight';
        break;
      case 'BILLBOARD':
        color = 'billboard';
        break;
      case 'CLP':
        color = 'clp';
        break;
      case 'GB':
        color = 'giantboard';
        break;
      case 'KB':
        color = 'kuleboard';
        break;
      case 'LUNA':
        color = 'luna';
        break;
      case 'ÜÇGENKB':
        color = 'ucgenkuleboard';
        break;      
      case 'S.KULE':
        color = 'skule';
        break;
      default:
        color = 'black';
    }
    
    
    return new Icon({
      iconUrl: require(`assets/img/icons/${color}.png`),
      shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
      iconSize: [25, 41],
      iconAnchor: [12, 41],
      popupAnchor: [1, -34],
      shadowSize: [41, 41]
    });
  }

  useEffect(() => {
    let isMounted = true; 
    const token = localStorage.getItem('token');
    const fetchData = async () => {
      console.log("Fetching data from the server...");
      try {
        const apiUrl = "http://127.0.0.1:8000/advertisementsformap"
        const response = await fetch(apiUrl, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        if (isMounted) {
          console.log(`Fetched ${data.ads.length} ads.`);
          console.log(data.ads);
          setAds(data.ads);
          setMapLoaded(true);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
  
    fetchData();

    return () => {
      isMounted = false;
    };
  }, []);
  
  const filteredAds = ads.filter(ad => selectedTur === 'ALL' || ad.tur === selectedTur);


  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <SimpleGrid mb='20px' columns={{ sm: 1, md: 1 }} spacing={{ base: "20px", xl: "20px" }}>
        {!mapLoaded && (
          <Center position="absolute" width="100%" height="100%" zIndex="700">
            <Spinner />
          </Center>
        )}
        <Select onChange={(e) => setSelectedTur(e.target.value)} placeholder="Türüne Göre Filtreleme"
          icon={<ChevronDownIcon />}
          sx={{
            borderColor: 'gray.300',
            _hover: { borderColor: 'gray.500' },
            _expanded: { borderColor: 'blue.500' },
            _focus: { boxShadow: 'outline' },
          }}>
          <option value="ALL">Hepsi</option>
          <option value="ALINLIK">Alınlık</option>
          <option value="ADML">ADML</option>
          <option value="BILLBOARD">Billboard</option>
          <option value="CLP">CLP</option>
          <option value="GB">Giant Board</option>
          <option value="KB">Kule Board</option>
          <option value="LUNA">Luna</option>
          <option value="ÜÇGENKB">Üçgen Kule Board</option>
          <option value="S.KULE">S Kule</option>
        </Select>
        <MapContainer 
        center={[39.91951783723879, 32.863290702180166]} 
        zoom={13} 
        style={{ height: '1000px', width: '100%' , }}
        whenReady={(mapInstance) => {
          console.log('Map is ready:', mapInstance);
          
        }}>
          <TileLayer
            url="https://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}"
            maxZoom={20}
            subdomains={['mt0', 'mt1', 'mt2', 'mt3']}
          />
          {filteredAds.map(ad => (
            <Marker key={ad.id} position={[ad.enlem, ad.boylam]} icon={getIconColor(ad.tur)}>
              <Popup>
            <div>
              <p><strong>ID:</strong> {ad.id}</p>
              <p><strong>NT:</strong> {ad.nt}</p>
              <p><strong>Tür:</strong> {ad.tur}</p>
              <p><strong>İlçe:</strong> {ad.ilce}</p>
              <p><strong>No:</strong> {ad.no}</p>
              <p><strong>Adres:</strong> {ad.adres || 'No Address'}</p>
              <p><strong>Yön:</strong> {ad.yon}</p>
              <p><strong>Tarif:</strong> {ad.tarif}</p>
              <p><strong>Google Maps Link:</strong> <a href={ad.googlemaps_link}>Link</a></p>
              <p><strong>Reklam Alanı (Eni):</strong> {ad.reklam_alani_eni}</p>
              <p><strong>Reklam Alanı (Boyu):</strong> {ad.reklam_alani_boyu}</p>
              <p><strong>Reklam Alanı (m2):</strong> {ad.reklam_alani_m2}</p>
              <p><strong>Haftalık Bedel:</strong> {ad.haftalik_bedel}</p>
              <p><strong>Aylık Bedel:</strong> {ad.aylik_bedel}</p>
              <p><strong>Üç Aylık Bedel:</strong> {ad.uc_aylik_bedel}</p>
              <p><strong>Altı Aylık Bedel:</strong> {ad.alti_aylik_bedel}</p>
              <p><strong>Kiralandı mı?</strong> {ad.rented ? 'Evet' : 'Hayır'}</p>
              <p><strong>Aktif mi?</strong> {ad.active ? 'Evet' : 'Hayır'}</p>
              <p><strong>Fotoğrafı:</strong> {ad.reklam_alani_photo_path ? <img src={ad.reklam_alani_photo_path} alt="Ad Photo" style={{ width: '100%' }} /> : 'Fotoğraf bulunamadı'}</p>

            </div>
          </Popup>
        </Marker>
      ))}
    </MapContainer>
    </SimpleGrid>
    </Box>
  );
}
