import { Input, Modal, ModalOverlay, ModalContent, ModalHeader, Flex, ModalCloseButton, ModalBody, Button, FormLabel } from "@chakra-ui/react";
import { useState, useEffect } from 'react';
import axios from 'axios';

export default function ModifyAdModal({ isOpen, onClose }) {
  const [id, setId] = useState("");
  const [eski_nt_kodu, setEski_nt_kodu] = useState("");
  const [nt, setNt] = useState("");
  const [tur, setTur] = useState("");
  const [ilce, setIlce] = useState("");
  const [no, setNo] = useState("");
  const [adres, setAdres] = useState("");
  const [yon, setYon] = useState("");
  const [tarif, setTarif] = useState("");
  const [enlem, setEnlem] = useState("");
  const [boylam, setBoylam] = useState("");
  const [googlemaps_link, setGooglemaps_link] = useState("");
  const [reklam_alani_eni, setReklam_alani_eni] = useState("");
  const [reklam_alani_boyu, setReklam_alani_boyu] = useState("");
  const [reklam_alani_m2, setReklam_alani_m2] = useState("");
  const [haftalik_bedel, setHaftalik_bedel] = useState("");
  const [aylik_bedel, setAylik_bedel] = useState("");
  const [uc_aylik_bedel, setUc_aylik_bedel] = useState("");
  const [alti_aylik_bedel, setAlti_aylik_bedel] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (isOpen && no) {
      setIsLoading(true);
      const token = localStorage.getItem('token');
      const apiUrl = `http://127.0.0.1:8000/advertisementsno/${no}`
      const config = {
        headers: { Authorization: `Bearer ${token}` }
      };
      axios.get(apiUrl, config)
        .then(response => {
          const adData = response.data;
          setId(adData.id);
          setEski_nt_kodu(adData.eski_nt_kodu);
          setNt(adData.nt);
          setTur(adData.tur);
          setIlce(adData.ilce);
          setNo(adData.no);
          setAdres(adData.adres);
          setYon(adData.yon);
          setTarif(adData.tarif);
          setEnlem(adData.enlem);
          setBoylam(adData.boylam);
          setGooglemaps_link(adData.googlemaps_link);
          setReklam_alani_eni(adData.reklam_alani_eni);
          setReklam_alani_boyu(adData.reklam_alani_boyu);
          setReklam_alani_m2(adData.reklam_alani_m2);
          setHaftalik_bedel(adData.haftalik_bedel);
          setAylik_bedel(adData.aylik_bedel);
          setUc_aylik_bedel(adData.uc_aylik_bedel);
          setAlti_aylik_bedel(adData.alti_aylik_bedel);
        })
        .catch(error => console.error(error))
        .finally(() => setIsLoading(false));
    }
  }, [isOpen, no]);

    const handleSubmit = async () => {
    const adData = { eski_nt_kodu, nt, tur, ilce, no, adres, yon, tarif, enlem, boylam, googlemaps_link, reklam_alani_eni, reklam_alani_boyu, reklam_alani_m2, haftalik_bedel, aylik_bedel, uc_aylik_bedel, alti_aylik_bedel };
    
    const stringFields = { eski_nt_kodu, nt, tur, ilce, no, adres, yon, tarif, googlemaps_link };
    for (const [key, value] of Object.entries(stringFields)) {
      if (typeof value !== 'string') {
        alert(`Girdiğiniz alan ${key} yazı olmalıdır. Lütfen kontrol ediniz.`);
        return;
      }
    }

    const floatFields = { enlem, boylam, reklam_alani_eni, reklam_alani_boyu, reklam_alani_m2, haftalik_bedel, aylik_bedel, uc_aylik_bedel, alti_aylik_bedel };
    for (const [key, value] of Object.entries(floatFields)) {
      if (isNaN(value)) {
        alert(`Girdiğiniz alan ${key} sayı (örn 06.34) olmalıdır. Lütfen kontrol ediniz.`);
        return;
      }
    }
    try {
      const token = localStorage.getItem('token');
      const apiUrl = `http://127.0.0.1:8000/advertisements/${id}`
      const config = {
        headers: { Authorization: `Bearer ${token}` }
      };
      const response = await axios.put(apiUrl, adData, config);
      console.log(response.data);
      if (response.status === 200) {
        alert(` ${response.data.id} numaralı reklam alanı başarıyla güncellendi.`);
        // Reset the state of each input field
        setId("");
        setEski_nt_kodu("");
        setNt("");
        setTur("");
        setIlce("");
        setNo("");
        setAdres("");
        setYon("");
        setTarif("");
        setEnlem("");
        setBoylam("");
        setGooglemaps_link("");
        setReklam_alani_eni("");
        setReklam_alani_boyu("");
        setReklam_alani_m2("");
        setHaftalik_bedel("");
        setAylik_bedel("");
        setUc_aylik_bedel("");
        setAlti_aylik_bedel("");

      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Yeni Reklam Alanı Düzenle</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
        <Flex direction="column">
          <FormLabel>Eski NT Kodu</FormLabel>
          <Input placeholder="Eski NT Kodu" value={eski_nt_kodu} onChange={(e) => setEski_nt_kodu(e.target.value)} />

          <FormLabel>NT</FormLabel>
          <Input placeholder="NT" value={nt} onChange={(e) => setNt(e.target.value)} />

          <FormLabel>Tür</FormLabel>
          <Input placeholder="Tür" value={tur} onChange={(e) => setTur(e.target.value)} />

          <FormLabel>İlçe</FormLabel>
          <Input placeholder="İlçe" value={ilce} onChange={(e) => setIlce(e.target.value)} />

          <FormLabel>No</FormLabel>
          <Input placeholder="No" value={no} onChange={(e) => setNo(e.target.value)} />

          <FormLabel>Adres</FormLabel>
          <Input placeholder="Adres" value={adres} onChange={(e) => setAdres(e.target.value)} />

          <FormLabel>Yön</FormLabel>
          <Input placeholder="Yön" value={yon} onChange={(e) => setYon(e.target.value)} />

          <FormLabel>Tarif</FormLabel>
          <Input placeholder="Tarif" value={tarif} onChange={(e) => setTarif(e.target.value)} />

          <FormLabel>Enlem</FormLabel>
          <Input placeholder="Enlem" value={enlem} onChange={(e) => setEnlem(e.target.value)} />

          <FormLabel>Boylam</FormLabel>
          <Input placeholder="Boylam" value={boylam} onChange={(e) => setBoylam(e.target.value)} />

          <FormLabel>Google Maps Link</FormLabel>
          <Input placeholder="Google Maps Link" value={googlemaps_link} onChange={(e) => setGooglemaps_link(e.target.value)} />

          <FormLabel>Reklam Alanı Eni</FormLabel>
          <Input placeholder="Reklam Alanı Eni" value={reklam_alani_eni} onChange={(e) => setReklam_alani_eni(e.target.value)} />

          <FormLabel>Reklam Alanı Boyu</FormLabel>
          <Input placeholder="Reklam Alanı Boyu" value={reklam_alani_boyu} onChange={(e) => setReklam_alani_boyu(e.target.value)} />

          <FormLabel>Reklam Alanı m2</FormLabel>
          <Input placeholder="Reklam Alanı m2" value={reklam_alani_m2} onChange={(e) => setReklam_alani_m2(e.target.value)} />

          <FormLabel>Haftalık Bedel</FormLabel>
          <Input placeholder="Haftalık Bedel" value={haftalik_bedel} onChange={(e) => setHaftalik_bedel(e.target.value)} />

          <FormLabel>Aylık Bedel</FormLabel>
          <Input placeholder="Aylık Bedel" value={aylik_bedel} onChange={(e) => setAylik_bedel(e.target.value)} />

          <FormLabel>Üç Aylık Bedel</FormLabel>
          <Input placeholder="Üç Aylık Bedel" value={uc_aylik_bedel} onChange={(e) => setUc_aylik_bedel(e.target.value)} />

          <FormLabel>Altı Aylık Bedel</FormLabel>
          <Input placeholder="Altı Aylık Bedel" value={alti_aylik_bedel} onChange={(e) => setAlti_aylik_bedel(e.target.value)} />

          <Button 
            colorScheme="green" 
            onClick={handleSubmit} 
            style={{ 
              marginTop: '20px', 
              marginBottom: '20px', 
              display: 'block', 
              marginLeft: 'auto', 
              marginRight: 'auto' 
            }}
          >
            Kaydet
          </Button>
        </Flex>
      </ModalBody>
      </ModalContent>
    </Modal>
  );
}