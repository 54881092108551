//route.js
import React from "react";

import { Icon } from "@chakra-ui/react";
import {
  MdBarChart,
  MdHome,
  MdInsertChart,
  MdBusiness,
  MdLock,
  MdMap
} from "react-icons/md";

// Admin Imports
import MainDashboard from "views/admin/anasayfa";
import Reklamlar from "views/admin/reklamlar";
import kiralamalar from "views/admin/kiralamalar";
import kiralamalarim from "views/admin/kiralamalarim";
import Userlar from "views/admin/userlar";
import reklamHarita from "views/admin/reklamHarita";
import reklamHaritaKiralik from "views/admin/reklamHaritaKiralik";
import reklamHaritaMusait from "views/admin/reklamHaritaMusait";
import reklamMusait from "views/admin/reklamlarMusait";

// Auth Imports
import login from "views/auth/login";
import register from "views/auth/register";

const email = localStorage.getItem('email');
const token = localStorage.getItem('token');

async function getUserSecurityLevel(email) {
  const response = await fetch(`/users/${email}/security_level`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    }
  });

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  const data = await response.json(); 

  return data.security_level;
}

// TODO: burada security level kontrolü yapılacak, her sayfanın görebileceği security level'ı olacak, eğer kullanıcının security level'ı o sayfanın security level'ından düşükse o sayfaya giremeyecek
let routes = [
  {
    name: "Anasayfa - Belediye",
    layout: "/admin",
    path: "/anasayfa",
    icon: <Icon as={MdHome} width='20px' height='20px' color='inherit' />,
    component: MainDashboard,
    showInMenu: true,
  },
  {
    name: "Reklamlar Alanları - Belediye",
    layout: "/admin",
    icon: <Icon as={MdBarChart} width='20px' height='20px' color='inherit' />,
    path: "/reklamlar",
    component: Reklamlar,
    showInMenu: true,
  },
  {
    name: "Kiralanabilir Reklam Alanları - Ajanslar",
    layout: "/admin",
    icon: <Icon as={MdBarChart} width='20px' height='20px' color='inherit' />,
    path: "/musaitReklamlar",
    component: reklamMusait,
    showInMenu: true,
  },
  {
    name: "Reklam Harita - Belediye",
    layout: "/admin",
    icon: <Icon as={MdMap} width='20px' height='20px' color='inherit' />,
    path: "/reklamHarita",
    component: reklamHarita,
    showInMenu: true,
  },
  {
    name: "Reklam Harita Kiralananlar - Belediye",
    layout: "/admin",
    icon: <Icon as={MdMap} width='20px' height='20px' color='inherit' />,
    path: "/reklamHaritaKiralik",
    component: reklamHaritaKiralik,
    showInMenu: true,
  },
  {
    name: "Reklam Harita Kiralanabilir - Ajanslar",
    layout: "/admin",
    icon: <Icon as={MdMap} width='20px' height='20px' color='inherit' />,
    path: "/reklamHaritaMusait",
    component: reklamHaritaMusait,
    showInMenu: true,
  },
  {
    name: "Kiralamalar - Belediye",
    layout: "/admin",
    icon: <Icon as={MdInsertChart} width='20px' height='20px' color='inherit' />,
    path: "/kiralamalar",
    component: kiralamalar,
    showInMenu: true,
  },
  {
    name: "Kiralamalarım - Ajanslar",
    layout: "/admin",
    icon: <Icon as={MdBusiness} width='20px' height='20px' color='inherit' />,
    path: "/reklamkiralamalarim",
    component: kiralamalarim,
    showInMenu: true,
  },
  {
    name: "Kullanıcılar - Belediye",
    layout: "/admin",
    icon: <Icon as={MdBusiness} width='20px' height='20px' color='inherit' />,
    path: "/userlar",
    component: Userlar,
    showInMenu: true,
  },
  {
    name: "Sign In",
    layout: "/auth",
    path: "/login",
    icon: <Icon as={MdLock} width='20px' height='20px' color='inherit' />,
    component: login,
  },
  {
    name: "Kayıt",
    layout: "/auth",
    path: "/register",
    icon: <Icon as={MdLock} width='20px' height='20px' color='inherit' />,
    component: register,
    showInMenu: false,
  },
];


export default routes;
