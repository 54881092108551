import {
  Flex,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  Input,
  useColorModeValue,
  Button,
  useDisclosure,
} from "@chakra-ui/react";

import Card from "components/card/Card";
import React, { useMemo, useState } from "react";
import { useGlobalFilter, usePagination, useSortBy, useTable } from "react-table";
import CreateAdModal from "./CreateKullaniciModal";
import ModifyAdModal from "./ModifyKullaniciModal";
import DeleteAdModal from "./DeleteKullaniciModal";
import FileUploadModal from "./FileUploadModal";
import ActivateUserModal from "./ActivateUserModal"; // Import the new modal

export default function DevelopmentTable({
  columnsData,
  tableData,
  isLoading,
  currentPage,
  totalPages,
  onPageChange,
  onReload,
}) {
  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => tableData, [tableData]);
  const { isOpen: isAddOpen, onOpen: onAddOpen, onClose: onAddClose } = useDisclosure();
  const { isOpen: isModifyOpen, onOpen: onModifyOpen, onClose: onModifyClose } = useDisclosure();
  const { isOpen: isDeleteOpen, onOpen: onDeleteOpen, onClose: onDeleteClose } = useDisclosure();
  const { isOpen: isUploadOpen, onOpen: onUploadOpen, onClose: onUploadClose } = useDisclosure();
  const { isOpen: isActivateOpen, onOpen: onActivateOpen, onClose: onActivateClose } = useDisclosure(); // For activate modal

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 5 },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    setGlobalFilter,
    state: { pageIndex, globalFilter },
    pageOptions,
  } = tableInstance;
  const canNextPage = currentPage < totalPages;
  const canPreviousPage = currentPage > 1;

  const nextPage = () => {
    if (canNextPage) onPageChange(currentPage + 1);
  };

  const previousPage = () => {
    if (canPreviousPage) onPageChange(currentPage - 1);
  };
  const [searchValue, setSearchValue] = useState(globalFilter);
  const handleSearch = (e) => {
    const value = e.target.value || undefined;
    setGlobalFilter(value);
    setSearchValue(value);
  };

  const textColor = useColorModeValue("secondaryGray.900", "white");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");

  if (isLoading) {
    return <div>Yükleniyor</div>;
  }

  return (
    <Card direction="column" w="100%" px="0px" overflowX={{ sm: "scroll", lg: "hidden" }}>
      <Flex px="25px" justify="space-between" mb="20px" align="center">
        <Text color={textColor} fontSize="22px" fontWeight="700" lineHeight="100%">
          Kullanıcılar
        </Text>
        <Input
          value={searchValue}
          onChange={handleSearch}
          placeholder="Basit Arama"
          w={{ sm: "100%", lg: "400px" }}
        />
        <Flex alignItems="center" pr={50} pt={50}>
          <Button onClick={onUploadOpen} colorScheme="purple" mr={3}>
            Dosya Yükle
          </Button>
          <Button onClick={onReload} colorScheme="blue" mr={5}>
            Yenile
          </Button>
          <Button onClick={onModifyOpen} colorScheme="green" mr={5}>
            Kullanıcı Düzenle
          </Button>
          <Button onClick={onDeleteOpen} colorScheme="red" mr={5}>
            Kullanıcı Sil
          </Button>
          <Button onClick={onActivateOpen} colorScheme="teal" mr={5}>
            Kullanıcı Aktif Et
          </Button>
        </Flex>
        <CreateAdModal isOpen={isAddOpen} onClose={onAddClose} />
        <ModifyAdModal isOpen={isModifyOpen} onClose={onModifyClose} />
        <DeleteAdModal isOpen={isDeleteOpen} onClose={onDeleteClose} />
        <FileUploadModal isOpen={isUploadOpen} onClose={onUploadClose} />
        <ActivateUserModal isOpen={isActivateOpen} onClose={onActivateClose} /> {/* Activate modal */}
      </Flex>
      <div style={{ overflowX: "auto" }}>
        <Table {...getTableProps()} variant="simple" color="gray.500" mb="24px">
          <Thead>
            {headerGroups.map((headerGroup, index) => (
              <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
                {headerGroup.headers.map((column, index) => (
                  <Th {...column.getHeaderProps(column.getSortByToggleProps())} pe="10px" key={index} borderColor={borderColor}>
                    <Flex justify="space-between" align="center" fontSize={{ sm: "10px", lg: "12px" }} color="gray.400">
                      {column.render("Header")}
                    </Flex>
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>
          <Tbody {...getTableBodyProps()}>
            {page.map((row, index) => {
              prepareRow(row);
              return (
                <Tr {...row.getRowProps()} key={index}>
                  {row.cells.map((cell, index) => (
                    <Td
                      {...cell.getCellProps()}
                      key={index}
                      fontSize={{ sm: "14px" }}
                      minW={{ sm: "150px", md: "200px", lg: "auto" }}
                      borderColor="transparent"
                    >
                      <Text color={textColor} fontSize="sm" fontWeight="700">
                        {cell.render("Cell")}
                      </Text>
                    </Td>
                  ))}
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </div>
      <Flex justifyContent="space-between" alignItems="center" pr={50} pt={50}>
        <Flex justifyContent="center" flex="1">
          Sayfa {currentPage} / {totalPages}
        </Flex>
        <Flex>
          <Button onClick={previousPage} disabled={!canPreviousPage} colorScheme="teal" variant="outline" mr={3}>
            Bir Önceki
          </Button>
          <Button onClick={nextPage} disabled={!canNextPage} colorScheme="teal" variant="outline">
            İleri
          </Button>
        </Flex>
      </Flex>
    </Card>
  );
}
