import React, { useState } from "react";
import axios from 'axios';
import { NavLink, useHistory } from "react-router-dom";
import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  useColorModeValue,
  Select,
  Stack,
} from "@chakra-ui/react";
import DefaultAuth from "layouts/auth/Default";
import illustration from "assets/img/auth/ankara.png";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { RiEyeCloseLine } from "react-icons/ri";

function Register() {
  const textColor = useColorModeValue("navy.700", "white");
  const textColorSecondary = "gray.400";
  const [show, setShow] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [userType, setUserType] = useState('');
  const [fullName, setFullName] = useState('');
  const [tcIdNo, setTcIdNo] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [officialCompanyName, setOfficialCompanyName] = useState('');
  const [isMuellif, setIsMuellif] = useState(false);
  const [muellifStartDate, setMuellifStartDate] = useState('');
  const history = useHistory();
  const [taxNumber, setTaxNumber] = useState('');
  const [taxPlatePhoto, setTaxPlatePhoto] = useState(null);
  const [signatureCircularPhoto, setSignatureCircularPhoto] = useState(null);
  const [tradeRegistryGazettePhoto, setTradeRegistryGazettePhoto] = useState(null);
  const [agencyAuthorizationDocument, setAgencyAuthorizationDocument] = useState(null); 
  const [formErrors, setFormErrors] = useState({});

  const handleClick = () => setShow(!show);



  const handleRegister = async (e) => {
    e.preventDefault();
  
    // Validate mandatory fields
    let errors = [];
    if (!email) {
        errors.push("E-posta gereklidir");
    } else if (!/\S+@\S+\.\S+/.test(email)) {
        errors.push("E-posta şu formatda olmalıdır: ornek@ornek.com");
    }
    if (!password) errors.push("Şifre gereklidir");
    if (!fullName) errors.push("Tam ad gereklidir");
    if (!phoneNumber) errors.push("Telefon numarası gereklidir");
    if (!userType) errors.push("Kullanıcı tipi gereklidir");
    if (!tcIdNo) {
      errors.push("TC Kimlik No gereklidir");
    } else if (isNaN(tcIdNo)) {
      errors.push("TC Kimlik No bir sayı olmalıdır");
    }

    if (errors.length > 0) {
      alert(errors.join("\n"));
      return;
    }
  
    // Prepare the JSON object with all user data
    let userData = {
      email,
      password,
      user_type: userType,
      full_name: fullName,
      tc_id_no: tcIdNo,
      phone_number: phoneNumber,
      official_company_name: officialCompanyName || "",
      tax_number: taxNumber || "",
      is_muellif: isMuellif,
      muellif_start_date: isMuellif ? muellifStartDate : "",
    };
  
    try {
      // First upload documents if any, then send registration data
      const documents = [
        { file: taxPlatePhoto, key: 'tax_plate_photo_path' },
        { file: signatureCircularPhoto, key: 'signature_circular_photo_path' },
        { file: tradeRegistryGazettePhoto, key: 'trade_registry_gazette_photo_path' },
        { file: agencyAuthorizationDocument, key: 'agency_authorization_document_path' }
      ];

      const results = await Promise.all(documents.map(doc => {
        if (doc.file) {
          const formData2 = new FormData();
          formData2.append('file', doc.file);
          return axios.post(`http://127.0.0.1:8000/uploadfile/`, formData2, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          }).then(response => {
            return { [doc.key]: response.data.filename };
          });
        }
        return Promise.resolve({ [doc.key]: null });
      }));

      results.forEach(result => {
        userData = { ...userData, ...result };
      });

      console.log("User Data:", userData);
  
      // Post the user data
      const registerResponse = await axios.post('http://127.0.0.1:8000/register', userData);
      console.log("Registration Response:", registerResponse);
    
      if (registerResponse.data.id) {
        alert("Kayıt başarılı. Hesabınız aktifleştirildikten sonra giriş yapabilirsiniz.");
        history.push("/auth/login");
      }
    } catch (error) {
      console.error('Registration Error:', error);
      if (error.response && error.response.status === 400) {
        alert('Bu e-posta adresi ile zaten bir hesap oluşturulmuş. Lütfen farklı bir e-posta adresi deneyin.');
      } else {
        alert('Kayıt sırasında bir hata oluştu. Lütfen tekrar deneyin. Hata: ' + error);
      }
    }
  };
  

  return (
    <DefaultAuth illustrationBackground={illustration} image={illustration}>
      <Flex
        maxW={{ base: "100%", md: "max-content" }}
        w='100%'
        mx={{ base: "auto", lg: "0px" }}
        me='auto'
        h='auto'
        alignItems='start'
        justifyContent='center'
        mb={{ base: "30px", md: "60px" }}
        px={{ base: "25px", md: "0px" }}
        mt={{ base: "10px", md: "5vh" }}
        flexDirection='column'
        
      >
        <Box me='auto'>
          <Heading color={textColor} fontSize='36px' mb='10px'>
            Reklam Yönetim Sistemi - Kayıt
          </Heading>
          <Text
            mb='36px'
            ms='4px'
            color={textColorSecondary}
            fontWeight='400'
            fontSize='md'>
            Reklam Yönetim Sistemine kayıt olmak için gereken bilgileri giriniz. 
          </Text>
        </Box>
        <Flex
          zIndex='2'
          direction='column'
          w={{ base: "100%", md: "420px" }}
          maxW='100%'
          background='transparent'
          borderRadius='15px'
          mx={{ base: "auto", lg: "unset" }}
          me='auto'
          mb={{ base: "20px", md: "auto" }}
        >
          <FormControl onSubmit={handleRegister}>
            <Input
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              isRequired={true}
              variant='auth'
              fontSize='sm'
              ms={{ base: "0px", md: "0px" }}
              type='email'
              placeholder='Email'
              mb='24px'
              borderRadius='md'
            />
            <InputGroup size='md'>
              <Input
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                isRequired={true}
                fontSize='sm'
                placeholder='Şifreniz'
                mb='24px'
                type={show ? "text" : "password"}
                variant='auth'
                borderRadius='md'
              />
              <InputRightElement display='flex' alignItems='center' mt='4px'>
                <Icon
                  color={textColorSecondary}
                  _hover={{ cursor: "pointer" }}
                  as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                  onClick={handleClick}
                />
              </InputRightElement            >
            </InputGroup>
            <Input placeholder='İsim soyisim' value={fullName} onChange={(e) => setFullName(e.target.value)} mb='24px' borderRadius='md' />
            <Input placeholder='TC Kimlik No' value={tcIdNo} onChange={(e) => setTcIdNo(e.target.value)} mb='24px' borderRadius='md' />
            <Input placeholder='Telefon No' value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} mb='24px' borderRadius='md' />
            <Select placeholder='Kullanıcı Tipi' value={userType} onChange={(e) => setUserType(e.target.value)} mb='24px' borderRadius='md'>
              <option value='belediye'>Belediye/Yönetim</option>
              <option value='kurumsal'>Kurumsal</option>
              <option value='şahıs'>Şahıs</option>
            </Select>
            {userType === 'kurumsal' && (
              <Stack>
                <Input placeholder='Şirket Adınız' value={officialCompanyName} onChange={(e) => setOfficialCompanyName(e.target.value)} mb='24px' borderRadius='md' />
                <Checkbox isChecked={isMuellif} onChange={(e) => setIsMuellif(e.target.checked)} mb='24px'>
                  Müellif mi?
                </Checkbox>
                {isMuellif && (
                  <Input
                    type="date"
                    placeholder='Müellif Başlangıç Tarihi'
                    value={muellifStartDate}
                    onChange={(e) => setMuellifStartDate(e.target.value)}
                    mb='24px'
                    borderRadius='md'
                  />
                )}
                <Input
                  placeholder='Vergi Numarası'
                  value={taxNumber}
                  onChange={(e) => setTaxNumber(e.target.value)}
                  mb='24px'
                  borderRadius='md'
                />
                <h2>Vergi Levhası</h2>
                <Input type='file' onChange={(e) => setTaxPlatePhoto(e.target.files[0])} mb='24px' borderRadius='md' /> 

                <h2>İmza Sirküsü</h2>
                <Input type='file' onChange={(e) => setSignatureCircularPhoto(e.target.files[0])} mb='24px' borderRadius='md' /> 

                <h2>Ticari Sicil Gazete</h2>
                <Input type='file' onChange={(e) => setTradeRegistryGazettePhoto(e.target.files[0])} mb='24px' borderRadius='md' /> 

                <h2>Müellif Belgeniz</h2>
                <Input type='file' onChange={(e) => setAgencyAuthorizationDocument(e.target.files[0])} mb='24px' borderRadius='md' /> 
      
              </Stack>
            )}
            
            <Button
              onClick={handleRegister}
              fontSize='sm'
              variant='brand'
              fontWeight='500'
              w='100%'
              h='50'
              mb='24px'>
              Kayıt Ol
            </Button>
            <Button as={NavLink} to="/auth/login" fontSize='sm' variant='brand' fontWeight='500' w='100%' h='50' mb='24px'>
              Giriş Yap
            </Button>
          </FormControl>
          
        </Flex>
      </Flex>
    </DefaultAuth>
  );
}

export default Register;

