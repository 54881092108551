export const columnsDataAdvertisingRenting = [
  {
    Header: "ID",
    accessor: "id"
  },
  {
    Header: "Reklam Numarası",
    accessor: "network_no"
  },
  {
    Header: "Kiralama Tipi",
    accessor: "kiralama_tipi"
  },
  {
    Header: "Kiralayan Ajans",
    accessor: "kiralayan_ajans"
  },
  {
    Header: "Ajans ID",
    accessor: "ajans_id"
  },
  {
    Header: "Kira Başlangıç",
    accessor: "kira_baslangic"
  },
  {
    Header: "Kira Bitiş",
    accessor: "kira_bitis"
  },
  {
    Header: "Reklam İçeriği",
    accessor: "reklam_icerigi"
  }
];