//src/components/sidebar/components/Content.js
import React from "react";
import { Box, Flex, Stack, Image } from "@chakra-ui/react";
import Brand from "components/sidebar/components/Brand";
import Links from "components/sidebar/components/Links";
import logo from "assets/img/abb-logo.png";

function SidebarContent(props) {
  const { routes } = props;

  return (
    <Flex direction='column' height='100%' pt='25px' px="16px" borderRadius='30px'>
      <Brand />
      <Stack direction='column' mb='auto' mt='8px'>
        <Box ps='20px' pe={{ md: "16px", "2xl": "1px" }}>
          <Links routes={routes} />
        </Box>
      </Stack>

      <Box
        mt='100px'
        mb='40px'
        borderRadius='30px'
        display="flex"
        justifyContent="center">
        <Image src={logo} alt="Logo" width="150px" />
      </Box>
    </Flex>
  );
}

export default SidebarContent;