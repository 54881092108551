import { Box, SimpleGrid, Link, Icon } from "@chakra-ui/react";
import { FiFileText } from "react-icons/fi"; // Example icon from react-icons

export const columnsDataAjans = [
  {"Header": "ID", "accessor": "id"},
  {
    Header: "E-posta",
    accessor: "email"
  },
  {
    Header: "Tam İsim",
    accessor: "full_name"
  },
  {
    Header: "Telefon Numarası",
    accessor: "phone_number"
  },
  {
    Header: "Kullanıcı Tipi",
    accessor: "user_type"
  },
  {
    Header: "Resmi Şirket Adı",
    accessor: "official_company_name"
  },
  {
    Header: "Vergi Numarası",
    accessor: "tax_number"
  },
  {
    Header: "Aktif mi",
    accessor: "is_active",
    Cell: ({ value }) => value ? 'Evet' : 'Hayır'
  },
  {
    Header: "Kayıt Tarihi",
    accessor: "registration_date"
  },
  {
    Header: "TC Kimlik No",
    accessor: "tc_id_no"
  },
  {
    Header: "Muellif mi",
    accessor: "is_author",
    Cell: ({ value }) => value ? 'Evet' : 'Hayır'
  },
  {
    Header: "Muellif Başlangıç Tarihi",
    accessor: "author_start_date"
  },
  {
    Header: "Vergi Levhası Fotoğraf",
    accessor: "tax_plate_photo_path",
    Cell: ({ value }) => (
      <Link href={value} isExternal>
        <Icon as={FiFileText} boxSize="24px" cursor="pointer" />
      </Link>
    )
  },
  {
    Header: "İmza Dairesi Fotoğraf",
    accessor: "signature_circular_photo_path",
    Cell: ({ value }) => (
      <Link href={value} isExternal>
        <Icon as={FiFileText} boxSize="24px" cursor="pointer" />
      </Link>
    )
  },
  {
    Header: "Ticaret Sicil Gazetesi Fotoğraf",
    accessor: "trade_registry_gazette_photo_path",
    Cell: ({ value }) => (
      <Link href={value} isExternal>
        <Icon as={FiFileText} boxSize="24px" cursor="pointer" />
      </Link>
    )
  },
  {
    Header: "Ajans Yetki Belgesi",
    accessor: "agency_authorization_document_path",
    Cell: ({ value }) => (
      <Link href={value} isExternal>
        <Icon as={FiFileText} boxSize="24px" cursor="pointer" />
      </Link>
    )
  },
];
