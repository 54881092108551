import React from "react";

// Chakra imports
import { Flex, useColorModeValue, Text } from "@chakra-ui/react";

// Custom components
import { HSeparator } from "components/separator/Separator";
import { Image } from "@chakra-ui/react";

import myNewLogo from "assets/img/abb_reklam_logo.jpeg";

export function SidebarBrand() {
  //   Chakra color mode
  let logoColor = useColorModeValue("navy.700", "white");

  return (
    <Flex align='center' direction='column'>
      <Image src={myNewLogo} w='100px' my='32px' /> {/* Changed logo */}
      
      <Text fontSize="xl" color={logoColor} mt='10px' fontWeight={"bold"}>ABB Reklam Yönetim Sistemi</Text> {/* Added title */}
      <HSeparator mb='20px' />
    </Flex>
  );
}

export default SidebarBrand;
