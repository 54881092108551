import React, { useEffect, useState } from 'react';
import { Box, SimpleGrid } from "@chakra-ui/react";
import DevelopmentTable from "./components/DevelopmentTable";// Update the import path as needed
import { columnsDataReklamlar } from "./variables/columnsData"; // Update the import path as needed

export default function Settings() {
  
  const [tableData, setTableData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const token = localStorage.getItem('token');

  const fetchData = async (page) => {
    setIsLoading(true);
    try {
      const apiUrl = `http://127.0.0.1:8000/advertisementsall?page=${page}&per_page=10`;
      const response = await fetch(apiUrl, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      setTableData(data.ads);
      setTotalPages(Math.ceil(data.total / 10));
    } catch (error) {
      console.error('Error fetching data:', error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchData(currentPage);
  }, [currentPage]);

  return (
    
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <SimpleGrid mb='20px' columns={{ sm: 1, md: 1 }} spacing={{ base: "20px", xl: "20px" }}>
        <DevelopmentTable
          isLoading={isLoading}
          columnsData={columnsDataReklamlar}
          tableData={tableData}
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={setCurrentPage}
          onReload={() => fetchData(currentPage)}
        />
      </SimpleGrid>
    </Box>
  );
}
